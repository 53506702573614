// These routes are to define routes between main website and sub websites
/**
 * Usage
 * this.$axios.get('players.playerList')
 */
export const routes = {
    auth: {
        login: {
            user: '/users/login',
        },
        logout: {
            user: '/users/session/logout',
        },
        verify: {
            user: '/users/verify-tfa-otp',
        },
    },
    players: {
        playerList: {
            user: '/player-management/players',
        },
    },
    master: {
        languages: {
            user: '/master/languages',
        },
        languageStrings: {
            user: '/master-language-string',
        },
    },
    users: {
        info: {
            user: '/users/me/info',
        },
        user: {
            user: '/users',
        },
        verifyFinanceOtp: {
            user: '/users/verify-finance-tfa-otp',
        },
        setupFinanceTfa: {
            user: '/users/setup-finance-tfa',
        },
        permissions: {
            user: '/users/permissions',
        },
    },
    public: {
        type: {
            user: '/white-label/type',
        },
    },
}
