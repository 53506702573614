export default function (context) {
    // This logic is to check if the currency is cookie is missing
    // If yes, then remove all the cookies and redirect to login page

    if (context.$cookie.get('token')) {
        if (!context.$cookie.get('currency')) {
            // Delete all sensitive cookies data
            context.$cookie.remove('token')
            context.$cookie.remove('user')
            context.$cookie.remove('currency_type')
            context.$cookie.remove('markup_1')
            context.$cookie.remove('markup_2')

            // Redirect user to Login Page
            return context.redirect('/login')
        }
    }
}
