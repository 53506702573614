export default {
    computed: {
        isLoadedErrorStringDone() {
            return this.$store.state['website-setting']['error-code'].isLoaded
        },
    },
    methods: {
        async getErrorCodeStrings(lang = 'en-US') {
            const locale = this.$cookie.get('locale') || lang

            await this.$store.dispatch(
                'website-setting/error-code/getErrorStrings',
                locale
            )
        },
    },
}
